import React from "react";

function PlanVisit({ setCntrlCom }) {
  return (
    <div className="modal-bg">
      <div className="textContainer">
        <div>
          <button
            className="btn-close"
            onClick={() => {
              setCntrlCom();
            }}
          >
            X
          </button>
        </div>
        <div className="titleText" style={{ textAlign: "center" }}>
          <h2>PLAN YOUR VISIT</h2>
        </div>
        <div className="titleText">
          <h2>Address</h2>
        </div>
        <div className="textStd">
          <p>6511 Dyke Road, Richmond&nbsp; BC</p>
          <p> V7E 3R3</p>
          <p>Phone:&nbsp;604-271-5220</p>
          <p>Website:&nbsp;londonfarm.ca</p>
        </div>
        <div className="titleText">
          <h2>Hours</h2>
        </div>
        <div className="textStd">
          <p style={{ fontWeight: "bold" }}>
            Park Grounds: Open Dawn to Dusk Year Round
          </p>
        </div>
        <div className="textStd">
          <p style={{ fontWeight: "bold" }}>London Farmhouse open hours:</p>
          <p style={{ fontWeight: "bold" }}>May 1st to August 31st:</p>
          <p> Thursday, Friday, Saturday, Sunday - 10am to 5pm.</p>
          <p style={{ fontWeight: "bold" }}>Sept 3rd to Dec 31st:</p>
          <p> Saturday & Sunday - 10am to 3pm.</p>
        </div>
        <div className="titleText">
          <h2>Admission</h2>
        </div>
        <div className="textStd">
          <p>Free*</p>
          <p>*Except for specific Posted events, (eg: Family Farm Day)</p>
        </div>
        <div className="titleText">
          <h2>Amenities</h2>
        </div>
        <div className="textStd">
          <p> - Tours of Farmhouse and Grounds</p>
          <p> - Picnic tables</p>
          <p> - Washrooms</p>
          <p> - Allotment Gardens</p>
          <p> - Heritage Gardens</p>
          <p> - Apiary (Honey Bee Hives)</p>
          <p> - Brood of Chickens</p>
          <p>- Farmhouse with Tea Room, Museum and Gift Shop</p>
        </div>
        <div className="titleText">
          <h2>Parking</h2>
        </div>
        <div className="textStd">
          <p>
            Free parking is available opposite the farm on the other side of
            Dyke Road.
          </p>
        </div>
        <div className="titleText">
          <h2>Accessibility</h2>
        </div>
        <div className="textStd">
          <p>
            The farmhouse has an accessible ramp into the building. Please note,
            there are gravel paths from the entrance of the farm that lead to
            and around the farmhouse and there are no elevators to the second
            floor of the farmhouse.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PlanVisit;
