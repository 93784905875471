import React, { useState } from "react";
import BeesThumbNails from "./BeesThumbNails.js";
import BeesVidPt1 from "./BeesVidPt1.js";
import BeesVidPt2 from "./BeesVidPt2.js";

import "./explore.css";

function ExpBees() {
  const [cntrlCom, setCntrlCom] = useState();

  function chgComponent(cmpnt) {
    setCntrlCom(cmpnt);
    console.log(cntrlCom);
  }

  return (
    <div className="">
      <div>
        <p className="expTitle">London Farm Honey Bees</p>
      </div>
      <div className="beesVideos">
        <div className="beesThumbnailsCntnr">
          <BeesThumbNails chgComponent={chgComponent} />
        </div>

        <div className="">
          {(() => {
            switch (cntrlCom) {
              case "beesPt1":
                return <BeesVidPt1 setCntrlCom={setCntrlCom} />;
              case "beesPt2":
                return <BeesVidPt2 setCntrlCom={setCntrlCom} />;
              default:
                return null;
            }
          })()}
        </div>
      </div>
    </div>
  );
}

export default ExpBees;
