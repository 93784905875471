import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

function HoneyBees2() {
  return (
    <div>
      <Routes>
        <Route path="*" element={<Navigate to="/explore" />} />
      </Routes>
    </div>
  );
}
export default HoneyBees2;
