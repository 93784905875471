import React from "react";
import "./explore.css";
import tnbeespt1 from "../img/tnbeespt1.png";
import tnbeespt2 from "../img/tnbeespt2.png";
//src="./bees/beespt1tnv4.jpg"
//src="./bees/beespt2tnv4.jpg"

function BeesThumbnails(props) {
  return (
    <div>
      <ul className="beesThumbnailsCntnr">
        <li onClick={() => props.chgComponent("beesPt1")}>
          <img
            className="beesThumbnailCard"
            src={tnbeespt1}
            alt="bees video pt1"
          ></img>
        </li>
        <li onClick={() => props.chgComponent("beesPt2")}>
          <img
            className="beesThumbnailCard"
            src={tnbeespt2}
            alt="bees video pt2"
          ></img>
        </li>
      </ul>
    </div>
  );
}
export default BeesThumbnails;
