import React, { useState } from "react";
import ExploreMenu from "./ExploreMenu";
import ExpHistory from "./ExpHistory";
import ExpChickens from "./ExpChickens";
import ExpBees from "./ExpBees";
import ExpPond from "./ExpPond";
import ExpFarmhouse from "./ExpFarmhouse";

import ExpHeritageGardens from "./ExpHeritageGardens";
import ExpAllotments from "./ExpAllotments";

function Explore() {
  const [cntrlExp, setCntrlExp] = useState("HoneyBees");

  function chgComponent(cmpnt) {
    setCntrlExp(cmpnt);
    console.log(cntrlExp);
  }
  /*<div className="expMenu">
					<div className="expMenuItems">*/
  return (
    <div className="i-top">
      <div className="expFlexCntnr">
        <div className="expMenu">
          <ExploreMenu chgComponent={chgComponent} />
        </div>

        <div className="">
          {(() => {
            switch (cntrlExp) {
              case "HoneyBees":
                return <ExpBees />;
              case "History":
                return <ExpHistory />;
              case "FarmHouse":
                return <ExpFarmhouse />;
              case "HeritageGardens":
                return <ExpHeritageGardens />;
              case "Chickens":
                return <ExpChickens />;
              case "Pond":
                return <ExpPond />;
              case "Allotments":
                return <ExpAllotments />;
              default:
                return null;
            }
          })()}
        </div>
      </div>
    </div>
  );
}

export default Explore;
