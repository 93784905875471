import React from "react";
import ReactPlayer from "react-player/youtube";

function BeesVidPt1({ setCntrlCom }) {
  return (
    <div className="modalBees">
      <div className="home-vid-size">
        <button
          className="btn-close"
          onClick={() => {
            setCntrlCom();
          }}
        >
          X
        </button>
        <div>
          <p className="textTitle">
            <h2 style={{ color: "white" }}>London Farm Honey Bees - pt 1</h2>
          </p>
        </div>
        <div>
          <div className="home-player-wrapper">
            <ReactPlayer
              className="react-player"
              url="https://www.youtube.com/watch?v=LPzqPvD0jz0&t=3s"
              fs="0"
              width="100%"
              height="100%"
              controls="true"
              playing="true"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default BeesVidPt1;
