import React from "react";
import { useForm } from "react-hook-form";
//import MCHomeMap from "../home/MCHomeMap.js";
import "./contactUs.css";

function ContactUs(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    // the host is determined by the start/build scripts in package.json

    const host = process.env.REACT_APP_EXPRESS_LHF;
    const expressUrl = host + "/routes/newContact";

    console.log(expressUrl);
    fetch(expressUrl, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(data),
      redirect: "follow",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    }).then((res) => {
      console.log(res.status);
      console.log("at props.endContactUs");
      //if (res.status === 200) {
      props.endContactUs();
    });
    //.catch(error => console.log(error));
    //});
  };

  return (
    <div className="modal-bg">
      <div className="textContainer">
        <div className="contactUs-form">
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              type="name"
              name="name"
              {...register("name", {
                required: true,
              })}
              placeholder="Your Name"
            />
            {errors.username && errors.username.message}

            <input
              type="email"
              name="email"
              {...register("email", {
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Should be a valid email",
                },
              })}
              placeholder="email"
            />
            {errors.email && <p className="error">{errors.email.message}</p>}

            <textarea
              name="textarea"
              {...register("textarea", {
                required: true,
                message: "There must be a message included",
              })}
              placeholder="Your message"
            />
            {errors.textarea && (
              <p className="error">{errors.textarea.message}</p>
            )}

            <button type="submit">SUBMIT</button>
          </form>
        </div>
        {/*<div className="mapCntnr">
        <div>
          <MCHomeMap />
        </div>
		</div>*/}
      </div>
    </div>
  );
}
export default ContactUs;
