/*IMPORTANT: This function is not funcioning- potential issues with the position of 
the display being too high for the modal-end. ALSO, unsure if ending properly
 Hiding this and continuing to use the PLan your visit page renamed Contact Us
 FOR NOW */
import React, { useState } from "react";
import ContactUs from "./ContactUs";
import ContactUsDone from "./ContactUsDone";

function ContactCntrlr({ props, setCntrlCom }) {
  const [ContactFormEnd, setContactFormEnd] = useState(false);

  const ContactUsEndForm = () => {
    setContactFormEnd(true);
    console.log(ContactFormEnd);
  };
  /* <div style={{ marginTop: "20px" }}>*/
  return (
    <div className="modal-bg">
      <div className="textContainer">
        <button
          className="btn-close"
          onClick={() => {
            setCntrlCom();
          }}
        >
          X
        </button>
      </div>
      <div>
        {ContactFormEnd ? (
          <ContactUsDone />
        ) : (
          <ContactUs {...props} endContactUs={ContactUsEndForm} />
        )}
      </div>
    </div>
  );
}
export default ContactCntrlr;
